import React from "react"
import Location from "./location"
import styled from "styled-components"
import Fade from "react-reveal/Fade"

const Wrapper = styled.div`
  margin-top: 30px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
`

const FaqData = [
  {
    question: "Europe (HQ)",
    answer: `Elgee Building, 3rd Floor, Magnet Rd, Townparks, Dundalk, Co. Louth, Ireland A91 YR9X`,
  },
  {
    question: "APAC",
    answer: `Level 35, One International Towers, 100 Barangaroo Avenue, Sydney NSW 2000, AU`,
  },
  {
    question: "North America",
    answer: `345 Park Avenue, Floor 17, Ireland House, 345 Park Avenue, New York 10154, US`,
  },
]

const Locations = () => {
  return (
    <Wrapper>
      {FaqData.map((faq, i) => (
        <Fade left>
          <Location
            key={"faq_" + i}
            question={faq.question}
            answer={faq.answer}
          />
        </Fade>
      ))}
      <br />
      <br />
    </Wrapper>
  )
}

export default Locations
