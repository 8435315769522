import { Link } from "gatsby"
import styled from "styled-components"

const StyledLink = styled(Link)`
  //border-bottom: 2px dashed var(--color-grey-500);
  background: linear-gradient(
      to bottom,
      transparent 62%,
      var(--color-accent-medium) 0
    )
    left center/0% 75% no-repeat;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: var(--transition);
`

export default StyledLink
