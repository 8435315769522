import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Boxed from "../bits/box"
import PageTitle from "../bits/pageTitle"
import GECKOFormation from "../components/GECKO/geckoFormation"
import StyledLink from "../bits/styledLink"
import Layout from "../template/index"
import SEO from "../utils/seo"
import Img from "gatsby-image"
import Locations from "../components/Location/index"

const AboutBoxed = styled(Boxed)`
  max-width: var(--page-container-l);
`

const Row = styled.section`
  padding: var(--var-padding-m) 0;
  background: white;
`

const ShortBoxed = styled(Boxed)`
  max-width: 50vw;
  margin: 0 auto;
  padding-top: 50px;

  @media only screen and (max-width: 1024px) {
    max-width: 80vw;
  }
`

const AltRow = styled(Row)`
  background: var(--color-white-700);
`

const InvertRow = styled(Row)`
  background: var(--color-secondary-700);
`

const WrapperIntro = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  margin-top: -100px;
  padding-bottom: 100px;

  @media only screen and (max-width: 768px) {
    margin-top: 0;
    display: block;
  }
`

const WrapperNav = styled.div`
  > * {
    display: inline-block;
    margin-right: 2rem;
  }
`

const GridBio = styled.div`
  display: grid;
  grid-template-columns: auto [P2] minmax(auto, 45ch);
`

const GridSkill = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 3fr) minmax(auto, 7fr);
  grid-gap: var(--var-padding-m);
  width: 100%;
  margin: var(--var-padding-m) 0;
`

const Intro = styled.div`
  grid-column: span 5 / 8;
  background: var(--color-background-500);
  padding: 2rem;
  z-index: 0;
  margin-left: 16px;
  @media only screen and (max-width: 768px) {
    padding: 0;
    background: none;
  }
`

const P1 = styled.div`
  grid-area: P1;
  max-width: 60ch;
`
const P2 = styled.div`
  grid-area: P2;
`

const DesignPart = styled.div`
  h3 {
    margin: 0;
  }
`
const DesignContent = styled.div``
const SkillList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    font-family: var(--font-primary);
    font-size: 1.2rem;
    color: var(--color-secondary-500);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--var-padding-m);
  }
`

const PassionPart = styled.div`
  h3 {
    margin: 0;
  }
`
const PassionContent = styled.div``

const PhotoFrame = styled.figure`
  grid-area: Photo;
  width: 100%;
  margin: 0;
  padding: 2rem 0;
`
const AboutFrame = styled.figure`
  grid-area: Photo;
  height: 100%;
  margin: 0;
  padding: 2rem 0;
`

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="About Us" />
      <Row>
        <AboutBoxed>
          <PageTitle title="About Us" subtitle="Little things that makes us." />
          <AboutFrame>
            <Img
              fluid={data.us.childImageSharp.fluid}
              width="100%"
              alt="About Cover Photo"
            />
          </AboutFrame>
          <WrapperIntro>
            <Intro>
              <small>Hey! We are GECKO.</small>
              <p>
                GECKO Governance is a RegTech system built by subject matter
                experts. Our team can translate our clients systematic processes
                and activities into the software, providing definitive solutions
                to meet all compliance pain-points across a multitude of
                financial service areas on a global basis.
                <br />
                <br />
              </p>
              <WrapperNav>
                <StyledLink to="/about#bio">
                  <small>Idea</small>
                </StyledLink>
                <StyledLink to="/about#resume">
                  <small>Formation </small>
                </StyledLink>
                <StyledLink to="/about#skills">
                  <small>Skills </small>
                </StyledLink>
                <StyledLink to="/about#location">
                  <small>Locations </small>
                </StyledLink>
              </WrapperNav>
            </Intro>
          </WrapperIntro>
        </AboutBoxed>
      </Row>
      <AltRow id="bio" className="full-bleed">
        <Boxed>
          <br />
          <P1>
            <small>Idea</small>
            <h2 className="no-margin">Once upon a time</h2>
            <p>
              An idea was born out of the frustration of being unable to source
              a streamlined system to manage multiple workflows and appropriate
              oversight of compliance processes that were traditionally carried
              out on spreadsheets.
            </p>
          </P1>
          <PhotoFrame>
            <Img
              fluid={data.cover.childImageSharp.fluid}
              height="100%"
              alt="Idea once upon a time"
            />
          </PhotoFrame>
          <GridBio>
            <P2>
              <p>
                It was time to develop a more efficient way and provide industry
                wide expertise and knowledge to financial services firms who
                need a solution to manage their governance framework,
                communication and documentation.
                <br />
                <br />
              </p>
            </P2>
          </GridBio>
        </Boxed>
      </AltRow>
      <InvertRow id="resume" className="full-bleed">
        <ShortBoxed>
          <br />
          <small>Formation</small>
          <GECKOFormation />
        </ShortBoxed>
      </InvertRow>

      <AltRow id="skills" className="full-bleed">
        <Boxed>
          <br />
          <small>Skills</small>
          <h2 className="no-margin">
            Regulatory, Compliance Knowledge, Tech and User Experience Sciences.
          </h2>
          <GridSkill>
            <DesignPart>
              <h3>Design</h3>
            </DesignPart>
            <DesignContent>
              <p>
              Having walked in your shoes, our experience working in financial services 
              knitted together with technology allows us to tailor our software for your 
              needs to address all pain-points, especially regulatory.{" "}
              </p>
              <SkillList>
                <li>Domain Knowledge / Compliance Expertise</li>
                <li>Rapid Development and Release Cycles</li>
                <li>Tailored to your business needs</li>
                <li>No code platform</li>
              </SkillList>
            </DesignContent>
          </GridSkill>
          <GridSkill>
            <PassionPart>
              <h3>Technology</h3>
            </PassionPart>
            <PassionContent>
              <p>
              Built with best practises and standards of open API (swagger) GECKO can accommodate a wide horizon of systems. Having GECKO at the core of an organization's infrastructure enables these systems to work together in harmony, avoiding teams being siloed and disconnected.
              </p>
              <SkillList>
                <li>Auditing</li>
                <li>Calendars</li>
                <li>Office 365</li>
                <li>API Centric</li>
                <li>Cloud Agnostic</li>
              </SkillList>
            </PassionContent>
          </GridSkill>
        </Boxed>
      </AltRow>
      <Row id="location" className="full-bleed">
        <Boxed>
          <br />
          <small>This is where we are</small>
          <h2 className="no-margin">Locations</h2>
          <Locations />
        </Boxed>
      </Row>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    cover: file(relativePath: { eq: "geckoHero.png" }) {
      childImageSharp {
        fluid(maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    us: file(relativePath: { eq: "geckoTeam.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
