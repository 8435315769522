import React from "react"
import styled from "styled-components"
import Items from "./items"
import Column from "../../bits/column"

const Section = styled.section`
  color: var(--color-accent-light);
  padding-left: 16px;
`

const Title = styled.h3`
  font-family: var(--font-primary);
  color: var(--color-accent-medium);
  margin: 0;
`

const ResumeSectionTitle = styled.small`
  color: var(--color-accent-medium);
  transform: rotate(90deg);
  transform-origin: top left;
  font-size: 1.25rem;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
    margin-left: 22px;
  }
`

const Block = styled.section`
  display: grid;
  grid-template-columns: 10ch 1fr;
  grid-gap: var(--var-padding-m);
  margin: 2rem 0;
  @media only screen and (max-width: 1024px) {
    grid-gap: 2rem;
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: 22px 1fr;
  }
`

const ResumeSection = styled(Column)`
  grid-gap: var(--var-padding-s) var(--var-padding-m);
`

const GECKOFormation = () => {
  return (
    <Section>
      <Title as="h1">Foundation of GECKO</Title>
      <Block>
        <ResumeSectionTitle>Team Formation</ResumeSectionTitle>
        <ResumeSection>
          <Items title="Revolutionary Idea" meta="2015" />
          <Items title="Team Formation" meta="2015 - 2016" />
          <Items title="GECKO version 1.0" meta="2016" />
        </ResumeSection>
      </Block>

      <Block>
        <ResumeSectionTitle>Raising $$</ResumeSectionTitle>
        <Items title="Secure Funding from VC in New York" meta="2017" />
      </Block>

      <Block>
        <ResumeSectionTitle>Digitalising Fund Industry</ResumeSectionTitle>
        <Items title="Serving the Fund Industry" meta="2017 - Now" />
      </Block>
    </Section>
  )
}

export default GECKOFormation
