import React, { useState } from "react"
import styled from "styled-components"

import { useSpring, animated } from "react-spring"
import useResizeAware from "react-resize-aware"

const LocWrapper = styled.div`
  margin-bottom: var(--var-padding-m);
`

const Question = styled.div`
  cursor: pointer;
  font-weight: 800;
`

const Answer = styled.div`
  overflow: hidden;

  opacity: 0.9;
  //border-bottom: 1px var(--color-accent-medium) dashed;
`

const Location = props => {
  const { question, answer } = props
  const [isOpen, toggleOpen] = useState(false)
  const [resizeListener, { height }] = useResizeAware()

  const animProps = useSpring({
    height: isOpen ? height : 0,
    opacity: isOpen ? 1 : 0,
  })

  return (
    <LocWrapper>
      <div onClick={() => toggleOpen(!isOpen)}>
        <Question>{question}</Question>
        <animated.div style={{ ...animProps }}>
          <Answer style={isOpen ? { display: "block" } : { display: "none" }}>
            <span style={{ position: "relative" }}>
              {resizeListener}
              {answer}
            </span>
          </Answer>
        </animated.div>
      </div>
    </LocWrapper>
  )
}

export default Location
